import React, { useState } from 'react';
import { post } from 'aws-amplify/api';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { Dialog, DialogContent, CircularProgress } from '@mui/material'; 


const CheckoutForm = (planSelection) => {
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); // State to manage dialog visibility

//   const [email, setEmail] = useState('');
// console.log(planSelection);
  
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    setOpenDialog(true); // Open the dialog when form is submitted
    setLoading(true); // Set loading state to true

    // Call your checkout API here, passing the email as a parameter
    try {
        const restOperation = await post({ 
            apiName: 'plotphraseappApi',
            path: '/create-checkout', 
            options: {
                body: {
                    customerId: `${planSelection.userid}`,
                    itemId: `${planSelection.priceId}`,
                    customerEmail: `${planSelection.email}`
                }
            }
        });
        const { body, statusCode } = await restOperation.response;
        const resObj = await body.json();
        // console.log("restOperation: ",restOperation, " body: ",body," Status Code: ",statusCode, `Post call succeeded: ${resObj.url}`);
      
      // Handle the response as needed
      if (statusCode === 200 ) {
        // window.location.href = resObj.url;
        window.open(resObj.url, '_blank');
      } else {
        console.error('Failed to create checkout session:', statusCode);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    } finally {
      // Hide or close the progress indicator after the new tab is opened
      setLoading(false);
      setOpenDialog(false); // Close the dialog when checkout process is complete
    }
  };

  return (
    <div style={{margin: "15px"}}> 
    <section>
      <form onSubmit={handleSubmit}>
        {/* <h3>Topup ${planSelection.price}</h3> */}
        <Card sx={{  display:'flex',justifyContent: 'center',alignContent: 'center' , boxShadow: 2, padding: '5px'}}>   
        <Button size="small" variant="contained"  type="submit" disabled={loading}>Topup ${planSelection.price}</Button>
        <Typography m={1} sx={{ fontSize : '12px'}}>{planSelection.text}</Typography>
        </Card>
        {/* Dialog component for showing progress */}
        <Dialog open={openDialog}>
          <DialogContent sx={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress sx={{ marginRight: '10px' }}/>
            <Typography variant="h6"  sx={{ marginLeft: '10px' }}>
              Redirecting to Stripe payment page...
            </Typography>
          </DialogContent>
        </Dialog>
      </form>
      </section>
    </div>
  );
};

export default CheckoutForm;
