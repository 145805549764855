
import { Amplify } from "aws-amplify";
import React from 'react';
import { useLocation } from 'react-router-dom';
import { withAuthenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { Heading, Image, useTheme, Text, Flex, Link  } from '@aws-amplify/ui-react';
import Logo from "../imges/plotphrase-icon/png/logo.png";
import "./styles.css";
import "./signin.css";
import Workspace from "../components/workspace/Workspace";
import config from '../amplifyconfiguration.json';
Amplify.configure(config);


// function Signin({ signOut, user }) {
//   return (
//     <>
//       <h1>Hello {user.username}</h1>
//       <button onClick={currentAuthenticatedUser}>getCurrentUser</button>
//       <button onClick={handleFetchUserAttributes}>fetchUserAttributes</button>
//       <button onClick={signOut}>Sign out</button>
//     </>
//   );
// }
// export default withAuthenticator(Signin);


export function Signin({ signOut, user }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isCanceled = queryParams.get('canceled') === 'true';

  if (isCanceled) {
    return (
      <div className="message-box">
        <h1>Stripe Payment Action Canceled!</h1>
        <p>
          You can safely close this window by selecting the Close button below. If you have been redirected 
          to this page due to an error, please reach out to <a href="mailto:contact@plotphrase.com">contact@plotphrase.com</a>.
        </p>
        <button onClick={() => window.close()}>Close</button>
        {/* <button onClick={() => window.close()}>Close Tab</button> */}
      </div>
    );
  }

    return (
      <main>
        <Workspace user={user} signOut={signOut} />
        {/* {console.log("Workspase user:",user)} */}
        {/* <h1>Hello {user.username}</h1> */}
        {/* <button onClick={signOut}>Sign out</button> */}
      </main>
    );
  }

export default withAuthenticator(Signin, {
    components: {
      Header,
      SignIn: {
        Header: SignInHeader,
        // Footer: SignInFooter
      },
      Footer
    }
  });
  
  export function Header() {
    const { tokens } = useTheme();
  
    return (
           <Image
            alt="Plotphrase logo"
            src={Logo}
            height="80%"
            width="80%"

            padding={tokens.space.medium}
          />
    );
  }

  export function Footer() {
    const { tokens } = useTheme();
  
    return (
      <Flex justifyContent="center" padding={tokens.space.medium}>
        <Text>&copy; All Rights Reserved</Text>
      </Flex>
    );
  }

  export function SignInHeader() {
    const { tokens } = useTheme();
  
    return (
      <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
        Sign in to your Account
      </Heading>
    );
  }

  // export function SignInFooter() {
  //   const { toResetPassword } = useAuthenticator();
  //   const { tokens } = useTheme();
  
  //   return (
  //     <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
  //       <Link onClick={toResetPassword}>Reset your password</Link>
  //     </Flex>
  //   );
  // }
