import { post } from 'aws-amplify/api';

async function callOpenai(inputText,dtype,userid) {
  try {
    const restOperation = post({ 
      apiName: 'plotphraseappApi',
      path: '/generateuml', 
      options: {
        body: {
          message: `${inputText}`,
          dtype: `${dtype}`,
          userid: `${userid}`,
        }
      }
    });

    const { body } = await restOperation.response;
    const resObj = await body.text();
    // console.log(`Post call succeeded: ${resObj}`);

    return resObj;
  } catch (error) {
    console.log('Post call failed: ', error);
    return error;
  }
}

export default callOpenai;