import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa';

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
	// {
	// 	name: 'Facebook',
	// 	icon: iconStyle(FaFacebook),
	// },
	// {
	// 	name: 'Instagram',
	// 	icon: iconStyle(FaInstagram),
	// },
	// {
	// 	name: 'YouTube',
	// 	icon: iconStyle(FaYoutube),
	// },
	// {
	// 	name: 'Twitter',
	// 	icon: iconStyle(FaTwitter),
	// },
	// {
	// 	name: 'LinkedIn',
	// 	icon: iconStyle(FaLinkedin),
	// },
];

// export const footerData = [
// 	// {
// 	// 	title: 'Main',
// 	// 	links: ['Blog', 'FAQs', 'Support', 'About us'],
// 	// },
// 	// {
// 	// 	title: 'Reach Us',
// 	// 	links: ['contact@phlotphrase.com'],
// 	// },
// 	{
// 		title: 'Legal',
// 		links: [{
// 			text: 'Privacy Policy', 
// 			link: 'Terms of Service',
// 		},]
// 	},
// 	// {
// 	// 	title: 'Product',
// 	// 	links: ['Login', 'Personal', 'Business', 'Team'],
// 	// },
// 	// {
// 	// 	title: 'Press',
// 	// 	links: ['Logos', 'Events', 'Stories', 'Office'],
// 	// },
// 	// {
// 	// 	title: 'Legal',
// 	// 	links: ['GDPR', 'Privacy Policy', 'Terms of Service', 'Disclaimer'],
// 	// },
// ];

export const footerData = [
	{
		title: 'Reach Us',
		links: [
			{text: 'contact@plotphrase.com', link: 'mailto:contact@plotphrase.com'}
		],
	},
	{
		title: 'Legal',
		links: [
            { text: 'Privacy Policy', link: '/privacy-policy' },
            { text: 'Terms of Service', link: '/terms-of-service' }
        ]
	}
];