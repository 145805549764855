import React, { useState } from 'react';
import {
  Card,
  CardMedia,
  CardActions,
  IconButton,
  Tooltip,
  Divider,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';


const ImageCard = ({ item, handleClick, handleDelete, onDownload }) => {
  return (
    <Card>
      <CardMedia
        component="img"
        sx={{ minHeight: '60px', minWidth: '60px', height: 'auto', width: '100%' }}
        image={item.img}
        onClick={() => handleClick(item.img)}  // Call handleClick with the image URL
        style={{ cursor: 'pointer' }} // Optional: to indicate the image is clickable
        loading="lazy" 
      ></CardMedia>
      <Divider  variant="middle" flexItem />
          <CardActions disableSpacing  sx={{ height: '40px', display: 'grid'}}>
            <IconButton
              // sx={{  bottom: 1,right: 1}}
              sx={{ position: 'absolute', bottom: 1,right: 1}}
              onClick={(e) => {
                e.stopPropagation(); // Prevent the click event from bubbling up to the CardMedia
                handleDelete(item);
              }}
            >
              <Tooltip title="Delete">
                <DeleteOutlineOutlinedIcon color="action" fontSize='small'/>
              </Tooltip>
            </IconButton>
            <IconButton
              sx={{ position: 'absolute', bottom: 1,left: 1}}
              onClick={(e) => {
                e.stopPropagation(); // Prevent the click event from bubbling up to the CardMedia
                onDownload(item);
              }}
            >
              <Tooltip title="Download">
                <DownloadOutlinedIcon fontSize='small' />
              </Tooltip>
            </IconButton>
          </CardActions>
    </Card>
  );
};



export default ImageCard;
