import React from 'react';
import { Link } from 'react-router-dom';
import { Section, Container, MainHeading } from '../../globalStyles';
import { HeroText, ButtonWrapper, HeroButton } from './HeroStyles';
import Samples from '../Samples/Samples';

const Hero = () => {
	return (
		// <HeroSection>
		<Section margin="auto" maxWidth="1280px" padding="150px 70px" inverse >
			{/* <HeroVideo src="./assets/hero1.mp4" autoPlay muted /> */}
			<Container>
				<MainHeading inverse>Generate Diagram from Text Prompt</MainHeading>
				<HeroText inverse>
				Experience the seamless mastery of AI in crafting stunning diagrams effortlessly and instantaneously. 
				</HeroText>
				<ButtonWrapper>
					<Link to="signin">
						<HeroButton>Create Digaram</HeroButton>
					</Link>
					{/* <HeroButton>Find More</HeroButton> */}
				</ButtonWrapper>
				<Samples />
			</Container>
		{/* </HeroSection> */}
		</Section>
	);
};

export default Hero;