import plantumlEncoder from 'plantuml-encoder';
import errorImage from '../imges/plotphrase-generatediagramerror.png'

export async function GeneratePlantumlDiagram(umlCode,userid) {
    // const fetchurl = 'https://plantuml-api.onrender.com/png/';
    const fetchurl = 'https://www.plantuml.com/plantuml/png/';
    let imageUrl;

    const encoded = plantumlEncoder.encode(umlCode);
    // console.log("Encoded:",encoded);

    // Create a custom error image
    const errorImageUrl = errorImage; 
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    // var urlencoded = new URLSearchParams();
    // urlencoded.append("umlcode", umlCode);
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        // body: urlencoded,
        redirect: 'follow'
    };

    try {
        // const response = await fetch(fetchurl, requestOptions);
        const response = await fetch(fetchurl + encoded, requestOptions);
        if (response.ok) {
            const blob = await response.blob();
            // console.log("Blob:", blob);
            const imageUrl = URL.createObjectURL(blob);
            // await uploadDiagram(blob,userid); // Upload to S3
            return imageUrl; // Resolve the promise with the image URL
        } else {
            return errorImageUrl; // Resolve the promise with the error image URL
        }
    } catch (error) {
        console.error(error);
        return errorImageUrl; // Resolve the promise with the error image URL
    }

}

