import React from 'react';

import { LiaLaptopCodeSolid } from "react-icons/lia";
import { FaRegHandshake } from "react-icons/fa6";
import { PiTextTFill } from "react-icons/pi";
import { BiSelectMultiple } from "react-icons/bi";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import { IoIosOptions } from 'react-icons/io';
const iconStyle = (Icon) => <Icon size="3rem" color="#0f0f0f" />;

export const featuresData = [
	{
		name: 'Ask in Plain Language',
		description: 'Share your thoughts in clear text, and watch as the system cleverly transforms them into meaningful diagrams',
		icon: iconStyle(PiTextTFill),
		// icon: "./assets/plotphrase-icon/png/logo.png",
		imgClass: 'one',
		// color: "#c21b69",
	},
	{
		name: 'Quick Start',
		description: 'Dive into a wide array of existing examples and guides for a swift beginning',
		icon: iconStyle(BsFillRocketTakeoffFill),
		imgClass: 'two',
	},
	{
		name: 'Export Diagram/UML Code',
		description: 'Download your diagram as an image or UML code for seamless integration with other platforms',
		icon: iconStyle(LiaLaptopCodeSolid),
		imgClass: 'three',
	},
	{
		name: 'User-Friendly Experience',
		description: 'Transform your ideas into visual diagrams instantly',
		icon: iconStyle(FaRegHandshake),
		imgClass: 'four',
	},

	{
		name: 'Expanded Choices',
		description: 'Explore a diverse range of \'Diagram Types\' to suit your needs',
		icon: iconStyle(BiSelectMultiple),
		imgClass: 'five',
	},
	{
		name: 'Customization',
		description: 'Add a personal touch to your diagrams with themes',
		icon: iconStyle(IoIosOptions),
		imgClass: 'six',
	},


];