import React, { useState, useEffect } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import MenuAppBar from './MenuAppBar';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Grid  from '@mui/material/Grid';
import './Workspace.css';
import ChatArea from './ChatArea';
import TabPanel from './TabPanel';
import getuserCredit from '../../service/getuserCredit';
// import ImageGalleryView from './ImageGallaryView';



// import { TabPanel } from '@aws-amplify/ui-react/dist/types/primitives/Tabs/TabsPanel';
export default function Workspace({user, signOut}) {
  // const [response, setResponse] = useState(localStorage.getItem('response') || '');
  const [response, setResponse] = useState('');
  // const [diag, setDiagram] = useState(localStorage.getItem('diag') || '');
  const [diag, setDiagram] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'cloudscape-design');
  const [rescode, setRescode] = useState(false);
  const [mode, setMode] = useState(false);
  const [creditAvailable, setCreditAvailable] = useState(0.0);
  const [initialCreditFetched, setInitialCreditFetched] = useState(false);

  useEffect(() => {
    localStorage.setItem('response',response.toString());
    localStorage.setItem('diag',diag.toString());
    localStorage.setItem('theme',theme.toString());
  },[response, diag,theme]);

  const getuserAvailableCredit = async () => {
    const resp = await getuserCredit(user.userId);
    // console.log("Get credit Response: \n" ,resp);
    if (resp.remainingCredit || typeof resp.remainingCredit === 'number') {
      setInitialCreditFetched(true); // Set initialDataFetched to true once initial data is fetched
      setCreditAvailable(resp.remainingCredit);
    } 
  };
   
  // UseEffect to fetch user credit initially when the component mounts
  useEffect(() => {
    getuserAvailableCredit(); // Call the getuserAvailableCredit function when the component mounts
  }, []);


  return (
      <div style={{ padding: '0px'}}>
        <MenuAppBar user={user} signOut={signOut} 
                    creditAvailable={creditAvailable} 
                    setCreditAvailable={setCreditAvailable}
                    getuserAvailableCredit={getuserAvailableCredit}
                    >   
        </MenuAppBar>
        <div className="work-section">
          <div className="left-sidebar">
            <ChatArea user={user}
                      response={response} setResponse={setResponse} 
                      diag={diag} setDiagram={setDiagram} 
                      setIsLoading={setIsLoading}
                      theme={theme} setTheme={setTheme}
                      setRescode={setRescode} 
                      creditAvailable={creditAvailable}
                      setCreditAvailable={setCreditAvailable}
                      getuserAvailableCredit={getuserAvailableCredit}
                      >
            </ChatArea>
          </div>
          <div className="right-area">
              <TabPanel user={user}
                        response={response} 
                        setResponse={setResponse} 
                        diag={diag} setDiagram={setDiagram} 
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        theme={theme}
                        setTheme={setTheme}
                        />
              {/* <div> */}
                {/* <ImageGallery /> */}
                {/* <ImageGalleryView /> */}
              {/* </div> */}
          </div>
        </div>
        {/* <div id="below"></div> */}
      </div>
  );
}