import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {
    Nav,
    NavbarContainer,
    NavLogo,
    NavIcon,
    MobileIcon,

  } from "../components/Navbar/NavbarStyles";

const PrivacyPolicy = () => {
    return (
        <div>
            <Box sx={{
                padding: '20px',
                width: 'auto',
                height: 'auto',
            }}>
            <Paper elevation={3} sx={{
                padding: '30px',
                width: 'auto',
                height: 'auto',
            }}>
            <a href="/"> <NavIcon src="./assets/plotphrase-icon/png/logo.png" alt="logo" /></a>
            <Typography variant="h6" sx={{ textAlign: 'center'}}>Privacy Policy</Typography>
            <Typography variant="body1">
            This Privacy Policy ("Policy") describes how Plotphrase ("Plotphrase", "we", "us", or "our") collects, uses, and shares personal information when you use our website (the "Site") and services (the "Service"). By accessing or using the Service, you agree to the terms of this Policy.
            </Typography >
            <Typography variant="subtitle1">
            1. Information We Collect
            </Typography>
            <Typography>
            1.1 Information You Provide: When you use the Service, you may provide us with personal information, such as your name, email address, and any other information you choose to provide.
            </Typography>
            <Typography>1.2 Usage Data: We may collect information about how you interact with the Service, including your IP address, browser type, pages visited, and other usage data.</Typography>
            <Typography>1.3 Cookies: We may use cookies and similar tracking technologies to enhance your experience on the Site and improve the Service. You can control cookies through your browser settings, but disabling cookies may limit your ability to use certain features of the Service.
            </Typography>
            <Typography>2. How We Use Your Information
            </Typography>
            <Typography>2.1 Provide and Improve the Service: We use the information we collect to provide and improve the Service, customize your experience, and analyze usage trends.
            </Typography>
            <Typography>2.2 Communications: We may use your contact information to send you updates, newsletters, or other communications about Plotphrase and our services. You can opt out of receiving these communications at any time by following the unsubscribe instructions provided in the messages.
            </Typography>
            <Typography>2.3 Analytics: We may use third-party analytics tools to analyze usage patterns and trends on the Site and Service. These tools may collect information about your interactions with the Service, including but not limited to, pages visited, time spent on each page, and referring website.
            </Typography>
            <Typography>3. Sharing of Information
            </Typography>
            <Typography>3.1 Service Providers: We may share your information with third-party service providers who assist us in providing the Service, such as hosting providers, analytics providers, and customer support services.
            </Typography>
            <Typography>3.2 Legal Compliance: We may disclose your information to comply with applicable laws, regulations, legal processes, or government requests, or to protect the rights, property, or safety of Plotphrase, our users, or others.
            </Typography>
            <Typography>3.3 Business Transfers: In the event of a merger, acquisition, reorganization, or sale of assets, your information may be transferred as part of the transaction. We will notify you via email or a prominent notice on the Site of any such change in ownership or control of your personal information.
            </Typography>
            <Typography>4. Data Security
            </Typography>
            <Typography>4.1 Security Measures: Plotphrase takes reasonable precautions to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
            </Typography>
            <Typography>5. Your Choices
            </Typography>
            <Typography>5.1 Opt-Out: You can opt out of receiving promotional communications from us by following the unsubscribe instructions provided in the messages or by contacting us directly.
            </Typography>
            <Typography>5.2 Cookies: You can control cookies through your browser settings. Please note that disabling cookies may limit your ability to use certain features of the Service.
            </Typography>
            <Typography>6. Children's Privacy
            </Typography>
            <Typography>6.1 Age Restrictions: The Service is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us immediately.
            </Typography>
            <Typography>7. Changes to this Policy
            </Typography>
            <Typography>7.1 Updates: We may update this Policy from time to time by posting the revised version on the Site. The revised Policy will be effective immediately upon posting. Your continued use of the Service after the posting of the revised Policy constitutes your acceptance of the changes.
            </Typography>
            <Typography>8. Contact Us
            </Typography>
            <Typography>8.1 Questions: If you have any questions or concerns about this Policy, please contact us at contact@plotphrase.com.
            </Typography>
            </Paper>
            </Box>
        </div>
    );
};

export default PrivacyPolicy;