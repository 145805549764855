import React, { useEffect, useState } from 'react';
import { list, downloadData, remove  } from 'aws-amplify/storage';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import ImageCard from './ImageCard';


const PAGE_SIZE = 2;
let nextToken = undefined;

const ConfirmDialog = ({ open, handleClose, handleConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          Are you sure you want to delete this diagram?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const ImageListView = () => {
    const [images, setImages] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);

    const handleClickOpen = (imgUrl) => {
      setCurrentImage(imgUrl);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      setCurrentImage(null); // Reset the current image when dialog closes
    };
  
    const handleDelete = (imgUrl) => {
      setConfirmDialogOpen(true);
      setImageToDelete(imgUrl);
      // console.log("Inside handleDelete: imageToDelete =",imageToDelete);
    };
    
    const handleConfirmDelete = async () => {
      try {
        const imagePath = imageToDelete.s3Path;
        // console.log("imagePath=",imagePath);
        await remove({ path: imagePath });
        setImages((Images) => Images.filter((item) => item.s3Path !== imagePath));
        setConfirmDialogOpen(false);
        setImageToDelete(null);
      } catch (error) {
        console.log('Error removing image from Gallery!', error);
      }
    };
  
    const handleCancelDelete = () => {
      setConfirmDialogOpen(false);
      setImageToDelete(null);
    };

    const onDownload = async (imgUrl) => {
      const link = document.createElement("a");
      link.download = 'ChatDGM' + imgUrl.img.substr(imgUrl.img.length - 10);
      link.href = imgUrl.img;
      link.click();
    };

    const loadNextPage = async () => {
        try {
            const response = await list({
              path: ({identityId}) => `protected/${identityId}/`,
              pageSize: PAGE_SIZE,
              nextToken: nextToken
            });
            // console.log("response: ", response);
            // console.log("nextToken: ", nextToken);
            // console.log("userid:", userid);
            if (response.nextToken) {
              nextToken = response.nextToken;
            } else {
              nextToken = undefined;
            }
      
            const downloadResults = await Promise.all(
              response.items.map(async (image) => {
                // console.log("image.path:", image.path);
                const downloadResult = await downloadData({
                  path: image.path,
                  // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
                  }).result;
      
                  const blob = await downloadResult.body.blob();
                  const imageUrl = URL.createObjectURL(blob);
                  // console.log(blob);
                  // console.log("Imageurl:", imageUrl);
                  const imageObj = {
                    img: imageUrl,
                    s3Path: image.path,
                  };
                  // console.log("imageObj:",imageObj);
                  return imageObj;
              })
            );
            // console.log("DownloadResults:",downloadResults);
          //   setImages((prevImages) => [...prevImages, ...downloadResults]);
            setImages(downloadResults);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    useEffect(() => {
      loadNextPage();
    }, []);

    return (
        <Box sx={{ width: "100%", height: "100%", overflowY: 'scroll', padding: '5px' }}>
          <ImageList variant="masonry" cols={4} gap={8}>
          {images.map((item, index) => (
            <ImageListItem key={item.img}>
              <ImageCard key={index} item={item} handleClick={handleClickOpen} handleDelete={handleDelete} onDownload={onDownload}/>
            </ImageListItem>
          ))}
          </ImageList>
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg" // You can adjust the size as needed
            >
              
            <DialogContent>
              {currentImage && (
                <img
                  src={currentImage}
                  alt="Enlarged"
                  style={{ width: 'auto', height: 'auto' }}
                />
              )}
            </DialogContent>
          </Dialog>
          <ConfirmDialog
            open={confirmDialogOpen}
            handleClose={handleCancelDelete}
            handleConfirm={handleConfirmDelete}
          />
        </Box>
    );
};

export default ImageListView;