export const data = [
	{
		to: '/',
		text: 'Features',
		id: 'about',
	},
	{
		to: '/',
		text: 'Pricing',
		id: 'pricing',
	},
	// {
	// 	to: '/',
	// 	text: 'Contact',
	// 	id: 'footer'
	// },
	{
		to: '/signin',
		text: 'Login',
		
	},
];