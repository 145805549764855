import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Image } from '@aws-amplify/ui-react';
import CheckoutForm from './CheckoutForm';
import { fetchUserAttributes } from 'aws-amplify/auth';


export default function MenuAppBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showCredit, setShowCredit] = React.useState(false);
  const [userAttributes, setUserAttributes] = React.useState({});

  React.useEffect(() => {
    const handleFetchUserAttributes = async () => {
      try {
        // Call fetchUserAttributes function to fetch user attributes
        const response = await fetchUserAttributes();
        // console.log("UserAttributes:", response);
        // Update userAttributes state with the fetched data
        setUserAttributes(response);
        // console.log("priceid1", process.env.REACT_APP_PRODUCT_TOP10);
        // console.log("priceid2", process.env.REACT_APP_PRODUCT_TOP20);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    };
  
    // Call handleFetchUserAttributes function when component mounts
    handleFetchUserAttributes();
  }, []);

  const creditShowHandleClick = () => {
    props.getuserAvailableCredit();
    setShowCredit(!showCredit);
    // console.log(props.user);
  };

  const handleMenu = (event) => {
    setShowCredit(false);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: '5px' }}>
      {/* <FormGroup>
      </FormGroup> */}
      <AppBar color='transparent' position="static" >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Image margin-right="1rem" width="10rem" src="./assets/plotphrase-icon/png/logo.png" alt="logo"></Image>   
          </Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit">
              <Typography variant='overline'>{userAttributes.name}</Typography>
              <AccountCircle />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
                <MenuItem onClick={creditShowHandleClick}>Credit</MenuItem>
                  {showCredit && 
                  <Box sx={{ padding: '5px',backgroundColor: '#f6f6f5',justifyContent: 'center',alignitems: 'center' , width: '300px',  borderBottom: 0.5}}>                  
                    <Typography m={2} sx={{fontWeight: 'bold'  ,fontSize: '12px', textAlign: 'center'}}>  Available Credit: {props.creditAvailable }</Typography>
                    <Typography m={2} sx={{textAlign: 'center', fontSize: "14px"}}>Topup Plans</Typography>
                    <CheckoutForm email={userAttributes.email} priceId={process.env.REACT_APP_PRODUCT_TOP10} 
                        price={10.00} text="[ Buy 50 Credits ] Approximately 50 diagrams can be generated." userid={props.user.userId}/>
                    <CheckoutForm email={userAttributes.email} priceId={process.env.REACT_APP_PRODUCT_TOP20} 
                        price={20.00} text="[ Buy 100 Credits ] Approximately 100 diagrams can be generated." userid={props.user.userId}/>
                  </Box>}
                <MenuItem onClick={props.signOut}>Signout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

