import React, { useEffect, useState } from 'react';
// import { sendMsgToOpenAI } from '../Service/openai';
import { GeneratePlantumlDiagram } from '../../service/GeneratePlantumlDiagram';
import Button from '@mui/material/Button';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { Grid, FormControlLabel, Switch } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import callOpenai from '../../service/callopenaiapi';
import Typography from '@mui/material/Typography';
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';


const ChatArea = (Props) => {
  const [inputText, setInputText] = useState('');
  const [dtype, setDtype] = React.useState(localStorage.getItem('dtype') ||'Sequence diagram');
  
  useEffect(() => {
    localStorage.setItem('inputText',inputText.toString());
    localStorage.setItem('dtype',dtype.toString());
  },[inputText,dtype]);

  const handleTypeChange = (event) => {
    setDtype(event.target.value);
  };
  
  // console.log(Props.setResponse);
  const handleTextChange = (e) => {
    setInputText(e.target.value);
  };
  // Handle new chat
  const handleNewChatButtonClick = () => {
    setInputText('');
    setDtype('Sequence diagram');
    Props.setDiagram('');
    Props.setResponse('');
  };


  // Validate the AI response
  function validateResponse(inputResponse) {
    // console.log("Validation recieved: \n" + inputResponse)
    const codeStart = "```plantuml";
    const codeEnd = "```";

    const startIdx = inputResponse.indexOf(codeStart);
    const endIdx = inputResponse.indexOf(codeEnd, startIdx + codeStart.length);

    if (startIdx === -1 || endIdx === -1) {
        // No PlantUML code found
        return inputResponse;
    }

    const plantUMLCode = inputResponse.substring(startIdx + codeStart.length, endIdx).trim();

    // Remove comments from the PlantUML code
    const codeWithoutComments = plantUMLCode.replace(/'[^'\n]*'/g, '');
    // console.log("codeWithoutComments:\n" + codeWithoutComments);
    return codeWithoutComments;
  }

  function updateThemeText(inputString, theme) {
    // Split the input string into an array of lines
    const lines = inputString.split('\n');
  
    // Find the index of the line starting with '@startuml'
    let startIndex = -1;
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].trim().startsWith('@startuml')) {
        startIndex = i;
        break;
      }
    }
  
    // Check if a line starting with '!theme' already exists
    let themeIndex = -1;
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].trim().startsWith('!theme')) {
        themeIndex = i;
        break;
      }
    }
  
    // Replace or insert the '!theme' line
    if (themeIndex !== -1) {
      lines[themeIndex] = `!theme ${theme}`;
    } else if (startIndex !== -1) {
      lines.splice(startIndex + 1, 0, `!theme ${theme}`);
    }
  
    // Join the modified lines back into a string
    const updatedString = lines.join('\n');
  
    return updatedString;
  }

  const handleButtonClick = async () => {
    try {
      Props.setIsLoading(true);
      let res;
      // const resp = await sendMsgToOpenAI(inputText,dtype);
      const resp = await callOpenai(inputText,dtype,Props.user.userId);
      // console.log("AI Response: \n" ,resp);
      Props.getuserAvailableCredit();
      const validatedRes = validateResponse(resp);
      if (validatedRes) {
        res =  updateThemeText(validatedRes,Props.theme);
      } else {
        // console.log('No PlantUML code found in the input paragraph.');
        return;
      }
      
      Props.setResponse(res);
      // console.log("Validated Plantuml Code: \n" + res);
      const diag = await GeneratePlantumlDiagram(res,Props.user.userId);
      // console.log("diag:", diag);
      Props.setIsLoading(false);
      Props.setDiagram(diag);
      Props.setRescode(false);
      // setInputText(''); // Clear the input after Enter is pressed
    } catch (error) {
      // Props.setResponse(error.message);
      // Props.setResponse("Oops! We have an issue while Generating UML Code. Please try again later.");
      Props.setIsLoading(false);
      console.error('Error:', error);
    }
  };

  return (
    <div className='background-div'>
      <Grid container spacing={1} justifyContent="center"  padding={'5px'}>
        <Typography variant="subtitle1" >
          AI Chat Prompt
        </Typography>
        <Grid container alignItems="flex-end" direction="column">
          <Grid item>
            <Tooltip title="New" placement="right-start">
              <IconButton onClick={handleNewChatButtonClick}><NoteAddRoundedIcon fontSize='small' /></IconButton>
            </Tooltip>
          </Grid>
            <textarea
              maxLength={1000}
              rows={27} // Number of visible rows
              placeholder="Start typing here...    "                                                              
              value={inputText}
              onChange={handleTextChange}
              // onKeyPress={handleKeyPress}
              style={{
                outlineColor: 'lightgray',
                backgroundColor: 'rgb(255 255 255)',
                border: '0px solid #ccc',
                borderRadius: '4px',
                padding: '10px',
                // width: '100%',
                fontSize: '14px',
                textAlign: 'left',
                fontWeight: '450',
                minWidth: '200px',
                width: '350px',
                maxWidth: '400px',
                minHeight: '250px',
                height: '350px',
                maxHeight: '370px',
                // font: 'IBM Plex Sans, sans-serif',
                padding: '12px',
                borderRadius: '12px 12px 0 12px',
              }}
            />
          
        </Grid>
      </Grid>

      <Grid container justifyContent="center"  padding={'5px'}>
        <Grid container justifyContent="center" padding={'5px'}>
          <FormControl variant="standard" size="small"  sx={{ m: 1, minWidth: 150}}>
          {/* <InputLabel id="demo-simple-select-standard-label">Type</InputLabel> */}
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-filled"
            value={dtype}
            onChange={handleTypeChange}
            label="dtype"
            defaultValue={dtype}
          >
            
            <MenuItem value={'Sequence diagram'}>Sequence diagram</MenuItem>
            {/* <MenuItem value={'AWS diagram'}>AWS diagram</MenuItem> */}
            <MenuItem value={'Class diagram'}>Class diagram</MenuItem>
            {/* <MenuItem value={'Object diagram'}>Object diagram</MenuItem> */}
            <MenuItem value={'Usecase diagram'}>Usecase diagram</MenuItem>
            <MenuItem value={'Component diagram'}>Component diagram</MenuItem>
            {/* <MenuItem value={'Deployment diagram'}>Deployment diagram</MenuItem> */}
            {/* <MenuItem value={'State diagram'}>State diagram</MenuItem> */}
            {/* <MenuItem value={'Timing diagram'}>Timing diagram</MenuItem> */}
            <MenuItem value={'Activity diagram'}>Activity diagram</MenuItem>
            {/* <MenuItem value={'Network diagram'}>Network diagram</MenuItem> */}
            
          </Select>
          </FormControl>
            {Props.creditAvailable  > 0 ? (
              <Button size="small" variant="contained" endIcon={<PlayArrowOutlinedIcon />} onClick={handleButtonClick} sx={{ m: 1, minWidth: 50}}>
              Generate
              </Button>
            ) : (
              <Button size="small" variant="disabled" endIcon={<PlayArrowOutlinedIcon />} onClick={handleButtonClick} sx={{ m: 1, minWidth: 50}}>
                    Generate
              </Button>
            )}

        </Grid>
      </Grid>
    </div>
  );
};

export default ChatArea;
