export const pricingData = [
    {
		title: 'Free Credit',
		price: '$0 per user during the Free trial period',
		numAcc: '1 account',
		features: ['Generate Diagrams (Approx. Upto 20)', 'Access to all Types of Diagrams', 'Access to all Themes', 'Export Diagram', 'Export UML Code'],
	},
	{
		title: 'Topup 10',
		price: 'Buy credits for $10 (one time payment)',
		numAcc: '1 account',
		features: ['Generate Diagrams (Approx. Upto 50)', 'Access to all Types of Diagrams', 'Access to all Themes', 'Export Diagram', 'Export UML Code'],
	},
	{
		title: 'Topup 20',
		price: 'Buy credits for $20 (one time payment)',
		numAcc: '1 account',
		features: ['Generate Diagrams (Approx. Upto 100)', 'Access to all Types of Diagrams', 'Access to all Themes', 'Export Diagram', 'Export UML Code'],
	
	},
	// {
	// 	title: 'Premium',
	// 	price: '$32.50 user/month after offer period',
	// 	numAcc: '50-1000 accounts',
	// 	features: [
	// 		'Secure accounts',
	// 		'Best for small business',
	// 		'Customizable',
	// 		'Easy Integration',
	// 		'Development Team',
	// 		'DNS & Domain included',
	// 	],
	// },

	// {
	// 	title: 'Enterprise',
	// 	price: '$55.50 user/month after offer period ',
	// 	numAcc: '1000+ Accounts',
	// 	features: ['Unlimited Storage', 'Scalability', 'Free DNS & Domain'],
	// },
];