import sample1 from './../imges/Activitydigrampaymentsystem.png';
import sample2 from './../imges/Classdiagram.png';
import sample3 from './../imges/ExplainhowoAuthauthenticationworks.png';

export const data = [
	{
		title: 'Activity Diagram',
		description:
			'Create a diagram explaining playment system',
		image: sample1,
		dark: false, 
	},
	{
		title: 'Class Diagram',
		description: 'Generate a class diagram',
		image: sample2,
		dark: false, 
	},
	{
		title: 'Sequence Diagram',
		description: 'Explain how oAuth authentication works',
		image: sample3,
		dark: true, 
	},
	{
		title: 'Activity Diagram',
		description:
			'Create a diagram explaining playment system',
		image: sample1,
		dark: true, 
	},
	{
		title: 'Calss Diagram',
		description: 'Generate a class diagram',
		image: sample2,
		dark: true, 
	},
	{
		title: 'Sequence Diagram',
		description: 'Explain how oAuth authentication works',
		image: sample3,
		dark: false, 
	},
];

export const sliderSettings = {
	arrows: false,
	slidesToShow: 3,
	focusOnselect: false,
	accessability: false,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 2,
			},
		},

		{
			breakpoint: 900,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};