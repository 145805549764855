import React from 'react';
import Hero from '../components/Hero/Hero';
import Navbar from '../components/Navbar/Navbar';
import Features from '../components/Features/Features';
// import Samples from '../components/Samples/Samples';
import Pricing from '../components/Pricing/Pricing';
import Footer from '../components/Footer/Footer';

const Landingpage = () => {
  return (
    <div>
    <Navbar />
    <Hero />
    {/* <Samples /> */}
    <Features />
    <Pricing />
    {/* <Content {...heroOne} /> 
    <Content {...heroTwo} />
    <Content {...heroThree} /> */}
    {/* <Carousel /> */}
    <Footer />
    </div>
  )
}

export default Landingpage