import React, { useState } from 'react';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { data, sliderSettings } from '../../data/SamplesData';
import { Row, Heading, Section, TextWrapper } from '../../globalStyles';
import {
	ButtonContainer,
	ReviewSlider,
	ImageWrapper,
	SamplesImage,
} from './SamplesStyles';

const Samples = () => {
	const [sliderRef, setSliderRef] = useState(null);

	return (
		<Section margin="auto" maxWidth="1280px" padding="50px 70px" >
				<Heading width="auto" >
				Explore Sample Showcase
				</Heading>

			<ReviewSlider {...sliderSettings} ref={setSliderRef}>
				{data.map((el, index) => (
					<ImageWrapper key={index}>
						<TextWrapper size="1.1rem" margin="1rem 1rem 1rem 1rem" weight="bold" color='white'>
							{el.title}
						</TextWrapper>
						<SamplesImage inverse={el.dark} src={el.image} />
						{/* <TextWrapper>
							<TextWrapper size="0.9rem" margin="2.7rem" weight="bold" color="Gray">
								Text-Prompt:
							</TextWrapper>
							<TextWrapper size="0.9rem" margin="0.7rem" color="white" >
								{el.description}
							</TextWrapper>
						</TextWrapper> */}


						{/* <CardButton>Learn More</CardButton> */}
					</ImageWrapper>
				))}
			</ReviewSlider>

			<Row justify="space-around" margin="1rem" wrap="wrap" >
				<ButtonContainer>
					<IconContext.Provider value={{ size: '2rem', color: '#1d609c' }}>
						<FaArrowCircleLeft onClick={sliderRef?.slickPrev} />
						<FaArrowCircleRight onClick={sliderRef?.slickNext} />
					</IconContext.Provider>
				</ButtonContainer>
			</Row>

		</Section>
	);
};

export default Samples;