import { get, post } from 'aws-amplify/api';

async function getuserCredit(userid) {
  try {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "");
    
    const restOperation = get({ 
      apiName: 'plotphraseappApi',
      path: `/getcredit/${userid}`, 
    });

    const { body } = await restOperation.response;
    const resObj = await body.json();

    return resObj;
  } catch (error) {
    console.log('Get UserCredit call failed: ', error);
    return error;
  }
}

export default getuserCredit;