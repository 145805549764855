import React, {useState, useEffect} from 'react';
import './DiagramMenu.css'; // Import CSS for styling (you can style it according to your preference)
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import Tooltip from '@mui/material/Tooltip';
import { GeneratePlantumlDiagram } from '../../service/GeneratePlantumlDiagram';
import copy from "copy-to-clipboard";
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import uploadDiagram from '../../service/uploadDiagram';
import CodeIcon from '@mui/icons-material/Code';



const DiagramMenu = (Props) => {
    // State to keep track of whether the button is disabled
  const [isDisabled, setIsDisabled] = useState(false);


    const onDownload = async () => {
    const link = document.createElement("a");
    link.download = 'ChatDGM' + Props.diag.substr(Props.diag.length - 10);
    link.href = Props.diag;
    link.click();
    };

    const copyToClipboard = async () => {
    try {
        // Fetch the image data from the Blob URL
        const response = await fetch(Props.diag);
        const blob = await response.blob();
        
        // Convert the image data to a Data URL
        const dataUrl = URL.createObjectURL(blob);

        // Copy the Data URL to the clipboard
        await navigator.clipboard.write([
        new ClipboardItem({ 'image/png': blob })
        ]);

        // console.log('Image copied to clipboard successfully.');
    } catch (error) {
        console.error('Failed to copy image to clipboard:', error);
    }
    };

    const bookMarkDiagram = async () => {
    try {
        // Fetch the image data from the Blob URL
        const response = await fetch(Props.diag);
        const blob = await response.blob();
        
        // Convert the image data to a Data URL
        // const dataUrl = URL.createObjectURL(blob);

        await uploadDiagram(blob,Props.user.userId); 

        
        // At the end of the function, disable the button
        setIsDisabled(true);
        localStorage.setItem(`bookmark-disabled-${Props.diag}`, 'true');

    } catch (error) {
        console.error('Failed to Bookmark image:', error);
    }
    };

    async function handleThemeChange(event) {
        Props.setIsLoading(true);
        Props.setTheme(event.target.value);
        // Props.setResponse(updateThemeText(Props.response, event.target.value));
        const res = updateThemeText(Props.response, event.target.value);
        Props.setResponse(res);
        const diag = await GeneratePlantumlDiagram(res,Props.user.userId);
        Props.setIsLoading(false);
        Props.setDiagram(diag);
    };


    function updateThemeText(inputString, theme) {
        // Split the input string into an array of lines
        const lines = inputString.split('\n');
        // console.log('UpdateTheme - Receive:', inputString);

        // Find the index of the line starting with '@startuml'
        let startIndex = -1;
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].trim().startsWith('@startuml')) {
            startIndex = i;
            break;
            }
        }
        
        // Check if a line starting with '!theme' already exists
        let themeIndex = -1;
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].trim().startsWith('!theme')) {
            themeIndex = i;
            break;
            }
        }
        
        // Replace or insert the '!theme' line
        if (themeIndex !== -1) {
            lines[themeIndex] = `!theme ${theme}`;
        } else if (startIndex !== -1) {
            lines.splice(startIndex + 1, 0, `!theme ${theme}`);
        } else {
            //if @startuml line doesnot exists!
            lines.unshift(`!theme ${theme}`);
        }
        
        // Join the modified lines back into a string
        const updatedString = lines.join('\n');
        // console.log('UpdateTheme - Return:', updatedString);
        
        return updatedString;
    }

    const clearOldBookmarks = () => {
        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);
          if (key.startsWith('bookmark-disabled-') && key !== `bookmark-disabled-${Props.diag}`) {
            localStorage.removeItem(key);
            i--; // Adjust the index after removing an item
          }
        }
      };
    // Use effect to watch for changes to the Props.diag
    useEffect(() => {
        // Clear old bookmark keys
        clearOldBookmarks();
        const isButtonDisabled = localStorage.getItem(`bookmark-disabled-${Props.diag}`) === 'true';
        setIsDisabled(isButtonDisabled);
      }, [Props.diag]);

  return (
    <div className="diagrammenu">
        <div className='leftmenuItems'>
            <label className='diagrammenuLabel'>Theme: </label>
            <select className='umlthemeSelect' name="selectedTheme" value={Props.theme} onChange={handleThemeChange}>
                {/* <option disabled selected hidden>Theme</option> */}

                <option value={'_none_'}>none</option>
                <option value={'amiga'}>amiga</option>               
                <option value={'aws-orange'}>aws-orange</option>          
                <option value={'black-knight'}>black-knight</option>        
                <option value={'bluegray'}>bluegray</option>            
                <option value={'blueprint'}>blueprint</option>           
                <option value={'carbon-gray'}>carbon-gray</option>         
                <option value={'cerulean'}>cerulean</option>            
                <option value={'cerulean-outline'}>cerulean-outline</option>    
                <option value={'cloudscape-design'}>cloudscape-design</option>   
                <option value={'crt-amber'}>crt-amber</option>           
                <option value={'crt-green'}>crt-green</option>           
                <option value={'cyborg'}>cyborg</option>              
                <option value={'cyborg-outline'}>cyborg-outline</option>      
                <option value={'hacker'}>hacker</option>              
                <option value={'lightgray'}>lightgray</option>           
                <option value={'mars'}>mars</option>                
                <option value={'materia'}>materia</option>             
                <option value={'materia-outline'}>materia-outline</option>     
                <option value={'metal'}>metal</option>               
                <option value={'mimeograph'}>mimeograph</option>          
                <option value={'minty'}>minty</option>               
                <option value={'mono'}>mono</option>                
                <option value={'plain'}>plain</option>               
                <option value={'reddress-darkblue'}>reddress-darkblue</option>   
                <option value={'reddress-darkgreen'}>reddress-darkgreen</option>  
                <option value={'reddress-darkorange'}>reddress-darkorange</option> 
                <option value={'reddress-darkred'}>reddress-darkred</option>    
                <option value={'reddress-lightblue'}>reddress-lightblue</option>  
                <option value={'reddress-lightgreen'}>reddress-lightgreen</option> 
                <option value={'reddress-lightorange'}>reddress-lightorange</option>
                <option value={'reddress-lightred'}>reddress-lightred</option>   
                <option value={'sandstone'}>sandstone</option>           
                <option value={'silver'}>silver</option>              
                <option value={'sketchy'}>sketchy</option>
                <option value={'sketchy-outline'}>sketchy-outline</option>     
                <option value={'spacelab'}>spacelab</option>            
                <option value={'spacelab-white'}>spacelab-white</option>      
                <option value={'superhero'}>superhero</option>           
                <option value={'superhero-outline'}>superhero-outline</option>   
                <option value={'toy'}>toy</option>
                <option value={'united'}>united </option>
                <option value={'vibrant'}>vibrant </option>

            </select>
        </div>
        <div className='rightmenuItems'>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    '& > *': {
                    m: 1,
                    },
                }}
                >

            <ButtonGroup size="small" aria-label="Small button group">
                
                <Tooltip title="Save to gallery">
                    <IconButton onClick={bookMarkDiagram} disabled={isDisabled} color='secondary'><BookmarkAddIcon fontSize='small' /></IconButton>
                </Tooltip>
                <Tooltip title="Flip mode">
                    <IconButton onClick={Props.handleFlipBackground}><InvertColorsIcon fontSize='small' /></IconButton>
                </Tooltip>
                <Tooltip title="Copy">
                    <IconButton onClick={copyToClipboard}><ContentCopyOutlinedIcon fontSize='small' /></IconButton>
                </Tooltip>
                <Tooltip title="Download">
                    <IconButton onClick={onDownload} ><DownloadForOfflineOutlinedIcon fontSize='small' /></IconButton>
                </Tooltip>

            </ButtonGroup>
            </Box>
        </div>
    </div>
  );
};

export default DiagramMenu;
