import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import Skeleton from '@mui/material/Skeleton';
import './Workspace.css';
import copy from "copy-to-clipboard";
// import Button from '@mui/material/Button';
// import Card from '@mui/material/Card';
import logo from '../../imges/giphy.gif';
import './TabPanel.css';
import DiagramMenu from './DiagramMenu';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
// import { Image } from '@mui/icons-material';
import diagBackground from '../../imges/dotted_grid_image_white_03.png';
import ImageListView from './ImageListView';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          {/* <Typography>{children}</Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabPanel(Props) {
  const [value, setValue] = React.useState(0);
  const storedFlipBackground = localStorage.getItem('flipBackground');
  const initialFlipBackground = storedFlipBackground !== null ? JSON.parse(storedFlipBackground) : false;
  const [flipBackground, setFlipBackground] = useState(initialFlipBackground);

  useEffect(() => {
    localStorage.setItem('flipBackground',flipBackground);
  },[flipBackground]);

  const handleFlipBackground = () => {
    setFlipBackground(!flipBackground);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const copyToClipboard = () => {
    copy(Props.response);
    // alert(`Copied!`);
  }

  return (
    <Box sx={{ width: '100%' }}>
       
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
          <Tab label="Diagram" {...a11yProps(0)} />
          <Tab label="UMLCode" {...a11yProps(1)} />
          <Tab label="Gallery" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
          
         <div aligncontent='Right' className="display-linebreak" >
         {/* <DiagramMenu {...Props}></DiagramMenu> */}
                  <Box component="pre" 
                          sx={{ 
                          p: 0, 
                          Width: '1100px',
                          height: '700px',
                          maxHeight: '700px',
                          maxWidth: '1100px',
                          bgcolor: 'white',
                          color: 'black',
                          boxShadow: 2,
                          borderRadius: 2,
                          fontSize: 12,
                          margin: '0px',
                          textAlign: 'center',
                          overflow: 'auto',
                          position: 'relative',
                          backgroundImage: `url(${diagBackground})`,
                          
                          }}>
                            { Props.diag && Props.response && !Props.isLoading  &&  
                                  <DiagramMenu {...Props} handleFlipBackground={handleFlipBackground}></DiagramMenu>
                            }
                            { Props.diag && Props.response && !Props.isLoading  ?   
                              // Display diagram  !ORIGINAL!      
                              // <div style={{ maxHeight: '150%', maxWidth: '150%', justifyContent: 'center', alignItems: 'center', height: '500px', width: '960px', display: 'flex',position: 'relative',
                              //   margin: '4px', alignContent:'center',border: '1px solid lightgray', backgroundColor: 'white', overflowY: 'auto', overflowX: 'auto', justifyContent: 'center',}}>
                              //     <img src={Props.diag} alt="Processed Diagram" />
                              // </div>
                              // <div>
                              
                                <div id="imageDiv" className={`img-wrapper ${flipBackground ? 'flipped' : ''}`}>
                                  <img src={Props.diag} alt="Processed Diagram" />
                                </div>
                              //</div> 
                            
                            : Props.isLoading &&
                            <div style={{ justifyContent: 'center', alignItems: 'center', height: '450px', width: 'auto', 
                            margin: '4px',  display: 'flex'}}>
                                <img src={logo} alt="loading..." />
                            </div>  
                            }
                  </Box>
          </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div aligncontent='Right' className="display-linebreak" >
                  <Box component="pre" 
                          sx={{ 
                          p: 0, 
                          Width: '1100px',
                          height: '700px',
                          maxHeight: '700px',
                          maxWidth: '1100px',
                          bgcolor: 'lightGray',
                          color: 'black',
                          boxShadow: 2,
                          borderRadius: 2,
                          fontSize: 12,
                          margin: '0px',
                          textAlign: 'left',
                          overflow: 'auto',
                          position: 'relative'
                          }}>
                      {/* { Props.response || ( */}
                      <div className='codemenubar'>
                        <Tooltip title="Copy">
                          <IconButton sx={{ color: 'gray' }} onClick={copyToClipboard}><ContentCopyOutlinedIcon fontSize='small' /></IconButton>
                        </Tooltip>
                      </div>
                      <div className='codeeditor' > {/* contentEditable="true"*/}
                        {Props.response}
                      </div>  
                  </Box>
                  {/* <Button size='small' onClick={copyToClipboard}>
                      Copy Code
                  </Button> */}
          </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <div aligncontent='Right' className="display-linebreak" >
                  <Box component="pre" 
                          sx={{ 
                          p: 0, 
                          Width: '1100px',
                          height: '700px',
                          maxHeight: '700px',
                          maxWidth: '1100px',
                          bgcolor: 'gray',
                          // opacity: '0.2',
                          color: 'black',
                          boxShadow: 2,
                          borderRadius: 2,
                          fontSize: 12,
                          margin: '0px',
                          textAlign: 'left',
                          overflow: 'auto',
                          position: 'relative'
                          }}>
                            <ImageListView/>   
                  </Box>
            
          </div>
      </CustomTabPanel>
    </Box>
  );
}