import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {
    Nav,
    NavbarContainer,
    NavLogo,
    NavIcon,
    MobileIcon,

  } from "../components/Navbar/NavbarStyles";


const TermsOfServicePage = () => {
    return (
        <div>
            <Box 
                    width={1200}
                    p={10}
                    display="flex"
                    alignItems="center"
                    
                   
                    >
            <Box >
            <Paper variant="elevation" sx={{padding:'30px'}}> 
            <a href="/"> <NavIcon src="./assets/plotphrase-icon/png/logo.png" alt="logo" /></a>
            <Typography variant="h6" sx={{ textAlign: 'center'}}>Terms of Service</Typography>
            <Typography variant="body1">
                These Terms of Service ("Terms") govern your access to and use of the Plotphrase website (the "Site") and any services provided by Plotphrase ("Plotphrase", "we", "us", or "our"), including but not limited to the AI-generated diagram code generation service ("Service"). By accessing or using the Service, you agree to be bound by these Terms.
            </Typography >
            <Typography variant="subtitle1">
                1. Use of Service
            </Typography>
            <Typography>
            1.1 Eligibility: You must be at least 18 years old to use the Service. By accessing or using the Service, you represent and warrant that you are 13 years of age or older.
            </Typography>
            <Typography>
            1.2 Access: Subject to these Terms, Plotphrase grants you a non-exclusive, non-transferable, revocable license to access and use the Service for your internal business purposes.
            </Typography>
            <Typography>
            1.3 AI-generated Diagram Code: Plotphrase utilizes artificial intelligence ("AI") algorithms to generate diagram code based on user input. You acknowledge and agree that the accuracy, completeness, and reliability of the diagram code generated by the AI are not guaranteed, and Plotphrase shall not be liable for any errors or inaccuracies in the generated code.
            </Typography>
            <Typography>
            1.4 PlantUML Integration: Plotphrase integrates with PlantUML, an open-source tool for generating diagrams. You acknowledge and agree that the use of PlantUML is subject to the terms and conditions of the applicable open-source license(s), and Plotphrase shall not be liable for any issues arising from your use of PlantUML.
            </Typography>
            <Typography>
            2. User Responsibilities
            </Typography>
            <Typography>
            2.1 Security: You are responsible for maintaining the security of your account credentials and for any activities that occur under your account. You agree to immediately notify Plotphrase of any unauthorized use of your account or any other security breach.
            </Typography>
            <Typography>
            2.2 Compliance: You agree to comply with all applicable laws, regulations, and third-party agreements in connection with your use of the Service.
            </Typography>
            <Typography>
            3. Privacy
            </Typography>
            <Typography>
            3.1 Privacy Policy: Your use of the Service is subject to Plotphrase's Privacy Policy, available at Privacy Policy. By using the Service, you consent to the collection, use, and disclosure of your personal information as described in the Privacy Policy.
            </Typography>
            <Typography>
            4. Intellectual Property
            </Typography>
            <Typography>
            4.1 Ownership: Plotphrase retains all rights, title, and interest in and to the Service, including all intellectual property rights therein. You acknowledge and agree that the Service and any materials provided by Plotphrase are protected by copyright, trademark, and other laws.
            </Typography>
            <Typography>
            4.2 Feedback: If you provide any feedback, suggestions, or ideas regarding the Service ("Feedback"), you hereby grant Plotphrase a perpetual, irrevocable, royalty-free, worldwide license to use, modify, reproduce, disclose, and distribute such Feedback for any purpose.
            </Typography>
            <Typography>
            5. Limitation of Liability
            </Typography>
            <Typography>
            5.1 Disclaimer: TO THE FULLEST EXTENT PERMITTED BY LAW, PLOTPHRASE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. PLOTPHRASE  SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICE.
            </Typography>
            <Typography>
            5.2 Indemnification: You agree to indemnify, defend, and hold harmless Plotphrase and its officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with your use of the Service or any violation of these Terms.
            </Typography>
            <Typography>
            6. Termination
            </Typography>
            <Typography>
            6.1 Termination: Plotphrase reserves the right to suspend or terminate your access to the Service at any time and for any reason without prior notice or liability.
            </Typography>
            <Typography>
            6.2 Effect of Termination: Upon termination of your access to the Service, all rights granted to you under these Terms will cease immediately, and you must cease all use of the Service.
            </Typography>
            <Typography>
            7. Miscellaneous
            </Typography>
            <Typography>
            7.1 Governing Law: These Terms shall be governed by and construed in accordance with the laws of Jurisdiction, without regard to its conflict of law provisions.
            </Typography>
            <Typography>
            7.2 Entire Agreement: These Terms constitute the entire agreement between you and Plotphrase regarding your use of the Service and supersede all prior or contemporaneous agreements and understandings, whether written or oral.
            </Typography>
            <Typography>
            7.3 Modification: Plotphrase reserves the right to modify or update these Terms at any time without prior notice. Any changes to these Terms will be effective immediately upon posting on the Site. Your continued use of the Service after the posting of revised Terms constitutes your acceptance of the changes.
            </Typography>
            <Typography>
            7.4 Contact Us: If you have any questions or concerns about these Terms, please contact us at contact@plotphrase.com.
            </Typography>
            </Paper>
            </Box>
            </Box>
        </div>
    );
};

export default TermsOfServicePage;