import { uploadData } from 'aws-amplify/storage';

async function uploadDiagram(diagram,userid) {
    try {
        // const filename = diagram.substr(diagram.lastIndexOf('/') + 1) + '_' + Date.now() + '.png';
        const filename = Date.now() + '.png';
        // const diagramData = new File( [diagram], filename);
        // console.log("Filename: ", filename);
        
        const result = await uploadData({
            // path: 'public/album/2024/1.jpg', 
            // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
            path: ({identityId}) => `protected/${identityId}/${userid}/images/${filename}`,
            data: diagram
            // options: {
            // onProgress // Optional progress callback.
            // }
        }).result;
        // console.log('Succeeded: ', result);
    } catch (error) {
        // console.log('Error Uploading image.', error);
        console.log('Error Uploading image.');
    }
}

export default uploadDiagram;