import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import GlobalStyle from './globalStyles';
import PrivacyPolicy from './pages/privacypolicy';
import TermsOfServicePage from './pages/termsofservice';



//Pages
import Landingpage  from "./pages/landingpage";
import Signin from "./pages/signin";
// import Pricing from "./pages/pricingpage";

const App = () => {
 return <Router>
  <GlobalStyle />
 
  <Routes>
    <Route path="/" element={<Landingpage/>} />
    <Route path="/signin" element={<Signin/>} />
    <Route path="/terms-of-service" element={<TermsOfServicePage/>} />
    <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
    {/* <Route path="/pricing" element={<Pricing/>} /> */}
  </Routes>
 </Router>
}

export default App
