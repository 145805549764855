import styled from 'styled-components';
import { Button } from '../../globalStyles';

export const HeroSection = styled.section`
	height: 55vh;
	background-position: center;
	background-size: cover;
	padding-top: clamp(50px, 15vh, 220px);
	box-shadow: inset 0 0 0 1000px rgba (0, 0, 0, 0.2);
	margin: auto;
	maxWidth: 1280px;
	// padding: 50px;
`;

export const HeroVideo = styled.video`
	object-fit: cover;
	width: 100%;
	height: 100%;
	background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
	top: 0;
	position: absolute;
	z-index: -1;
`;

export const HeroText = styled.p`
	margin-bottom: 35px;
	font-size: clamp(0.9rem, 1.5vw, 1.3rem);
	line-height: 24px;
	text-align: center;
	letter-spacing: 2px;
	color: ${({ inverse }) => (inverse ? '$403ae3' : '#fff')};
`;

export const ButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
	gap: 0.5rem;
	padding: 0px 0px 25px 0px;
`;

export const HeroButton = styled(Button)`
	color: white;
	border-radius: 24px;

	&:before {
		background: #F5AA33;
		height: 500%;
	}

	&:hover:before {
		height: 0%;
	}

	&:hover {
		color: black;
		background: #FFBB4E;
	}
`;